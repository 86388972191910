import * as React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Layout from '../components/layout';

const KarrierePage = ({ data, pageContext }) => {
  
  return (
    <Layout title={data.mdx.frontmatter.title} heroTitle={' '} imageData={data.mdx.frontmatter.hero_image.childImageSharp.gatsbyImageData} pageContext={pageContext}>
      <section className="section">
        <div className="container">
          <h1 className="title">
            {data.mdx.frontmatter.title}
          </h1>

          <div className="content">
            <MDXRenderer className="box">
              {data.mdx.body}
            </MDXRenderer>
          </div>

        </div>
      </section>
    </Layout>
  )
}

export const careerQuery = graphql`
  query CareerByID($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        description
        title
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
      body
    }
  }
`;

export default KarrierePage;
